// PAGE ACTIONS
export const PAGE_LOADING = 'PAGE_LOADING';
export const PAGE_LOADED = 'PAGE_LOADED';


//AUT Action Types
export const LOGIN_USER = 'LOGIN_USER'
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL'
export const SET_CURRENT_USER = 'SET_CURRENT_USER'


export const GET_USER_DETAILS = 'GET_USER_DETAILS'
export const GET_RECENT_TRANSACTION = 'GET_RECENT_TRANSACTION'

export const GET_ALL_CREDIT_CARDS = 'GET_ALL_CREDIT_CARDS'